<template>
  <main :class="mainClass">
    <b-container
      v-if="title"
      class=" mb-4 pt-2 pb-2 text-white bg-dark"
      fluid
    >
      <b-row>
        <b-col cols="12">
          <h2 class="text-center">{{ title }}</h2>
        </b-col>
      </b-row>
    </b-container>
    <slot></slot>
  </main>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
    },
    isHome: {
      type: Boolean,
      required: false,
      default: false,
    },
    isStandalone: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    mainClass() {
      return (this.isHome || this.isStandalone) ? "flex-grow-1 d-flex flex-column" : "";
    },
  },
};
</script>

<style lang="scss" scoped>
</style>