<template>
  <page :title="title">
    <b-container fluid>
      <insta-feed
        token="IGQVJVVXIwTXVqaGdrQzZAocHp5ZAm1IdjNZALVl1REh5NXcwZAzJkVkNkUm96WEZAETGdxWWFHdzVhREh3UmF0cDZAPOUxwTTUxdUFTLWRfVzl0SXViU2UyNFBKVnNWVGxIUjE0OTJZAMUtCcFJNbW5xUHJrcgZDZD"
        fields="media_url,media_type,caption,permalink"
        container-class="image-container"
        :mediatypes="['IMAGE']"
        addlast
        @feedloaded="feedloaded"
      >
        <template v-slot:loading="props">
          <b-col
            v-if="props.loading"
            sm="12"
            md="3"
            lg="2"
            align-self="stretch"
            class="mb-3"
          >
            <b-card
              no-body
              bg-variant="info"
              text-variant="light"
              class="h-100"
            >
              <b-aspect aspect="1">
                <b-card-body>
                  <b-card-title title-tag="h3"
                    >Loading, please wait</b-card-title
                  >
                </b-card-body>
              </b-aspect>
            </b-card>
          </b-col>
        </template>

        <template v-slot:feeds="props">
          <insta-card
            v-for="item in props.items"
            :key="item.id"
            :igid="item.id"
            :imgsrc="item.media_url"
            :caption="item.caption"
            :link="item.permalink"
            :idx="item.idx"
            @cardClick="cardClick"
          ></insta-card>
        </template>
      </insta-feed>
    </b-container>
  </page>
</template>

<script>
// @ is an alias to /src
import Page from "@/views/Page.vue";
import InstaFeed from "@/components/InstaFeed.vue";
import InstaCard from "@/components/InstaCard.vue";

export default {
  name: "Feed",
  data() {
    return {
      title: "recent creations",
      feeds: [],
    };
  },
  components: {
    Page,
    InstaFeed,
    InstaCard,
    // Carousel,
  },
  methods: {
    cardClick(idx) {
      this.$store.commit("setModalSlideshowFromInstafeed", this.feeds);
      this.$store.commit("setCarouselSlide", idx);
      this.$bvModal.show('bvmodal');

    },
    feedloaded(result) {
      console.log("feedloaded called");
      this.feeds = result;
    },
  },
};
</script>

<style scoped>
</style>
