<template>
  <div id="app">
    <modal-newsletter></modal-newsletter>
    <modal-slideshow></modal-slideshow>
    <div id="fullapp" class="d-flex flex-column">
      <nav-bar></nav-bar>
      <keep-alive include="Feed">
        <router-view />
      </keep-alive>
    </div>
    <page-footer></page-footer>
  </div>
</template>

<script>
import ModalSlideshow from "./components/ModalSlideshow.vue";
import ModalNewsletter from "./components/ModalNewsletter.vue";
import NavBar from "./components/NavBar.vue";
import PageFooter from "./components/PageFooter.vue";
export default {
  components: {
    NavBar,
    PageFooter,
    ModalSlideshow,
    ModalNewsletter,
  },
  computed: {
    isNotStandalone() {
      return this.$route.name !== "Policy";
    },
  },
  watch: {
    $route(to) {
      window._hsq.push(["setPath", to.path]);
      window._hsq.push(["trackPageView"]);
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200;300;400;500;600;700;900&display=swap");

html,
body,
#app {
  height: 100%;
  font-family: "Noto Serif JP", serif;
  /* font-weight:500; */
}

#fullapp {
  min-height: 100%;
}

@media (max-width: 768px) {
  #hs-eu-cookie-confirmation.hs-cookie-notification-position-bottom {
    position: fixed;
    bottom: 0;
    top: auto;
    width: 100%;
  }
}
</style>
