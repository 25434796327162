import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

import VueAnalytics from 'vue-analytics';
import store from './store'
Vue.use(VueAnalytics, {
  id: 'UA-185117167-1',
  router
});

Vue.config.productionTip = false

import VueMq from 'vue-mq';
Vue.use(VueMq, {
  breakpoints: {
    sm: 576,
    md: 768,
    lg: Infinity,
  }
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

