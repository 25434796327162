<template>
  <page :title="policiesList[name].title" isStandalone>
    <iframe class="flex-grow-1" :src="policiesList[name].embedURL"></iframe>
  </page>
</template>

<script>
import Page from "./Page.vue";
export default {
  components: { Page },
  props: [ "name" ],
  data() {
    return {
      policiesList: {
        "privacy": {
          title: "Personal Data Protection Policy",
          embedURL: "https://docs.google.com/document/d/e/2PACX-1vT5c74eobV1-0vj3_6T7_c9sfjfLhkLn8obswtL3ChgSf7pGvEjVJ2jRJDfiAk_EA/pub?embedded=true"
        },
        "cookies": {
          title: "Cookie Management Policy",
          embedURL: "https://docs.google.com/document/d/e/2PACX-1vTn6YQkLugw2FfjM_b_lfFzHBx8o1zi2DfI2a1G4VjfRqoXFppzcEv4PiMewGcyYw/pub?embedded=true"
        },
        "terms-of-use": {
          title: "Terms of Use",
          embedURL: "https://docs.google.com/document/d/e/2PACX-1vTc12vBKmTs-zHQhkkeBls1f8wKU7upUa836-N8sEa60oKfsxVao9yKrckFtgf7Cg/pub?embedded=true"
        }
      },
    }
  },
  computed: {
    // title() {
    //   return this.policiesList[this.name].title;
    // },
    // embedURL() {
    //   return this.policiesList[this.name].title;
    // }
  }
};
</script>

<style scoped>
iframe {
  width: 100%;
  border: 0;
  max-width: 595pt;
  margin: auto;
} 
</style>