import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    modalSlideshow: [
      {
        name: "first",
        relURL: "/media/collections/Intertwined/Fichier_000.jpeg",
        caption: "caption1",
      },
      {
        name: "second",
        relURL: "/media/collections/Intertwined/Fichier_001.jpeg",
        caption: "caption1",
      },
      {
        name: "third",
        relURL: "/media/collections/Intertwined/Fichier_002.jpeg",
        caption: "caption1",
      },
    ],
    currentSlide: 0,
    collections: [
      {
        name: "Intimate",
        tagline: "In the Privacy of lace",
        description:
          "A collection made with high-quality acetate and exclusive French luxury Lace from Calais. A hide and seek game of transparencies from the seen to the unseen.",
        starting_price: 790,
        slideshow: [
          {
            filename: "Fichier_000.jpeg",
            caption: "Mon Miroir Intimate Collection",
          },
          {
            filename: "Fichier_001.jpeg",
            caption: "Mon Miroir Intimate Collection",
          },
          {
            filename: "Fichier_002.jpeg",
            caption: "Mon Miroir Intimate Collection",
          },
          {
            filename: "Fichier_003.jpeg",
            caption: "Mon Miroir Intimate Collection",
          },
          {
            filename: "Fichier_004.jpeg",
            caption: "Mon Miroir Intimate Collection",
          },
          {
            filename: "Fichier_005.jpeg",
            caption: "Mon Miroir Intimate Collection",
          },
          {
            filename: "Fichier_006.jpeg",
            caption: "Mon Miroir Intimate Collection",
          },
          // {
          //   filename: "Fichier_007.jpeg",
          //   caption: "Mon Miroir Intimate Collection",
          // },
          {
            filename: "Fichier_008.jpeg",
            caption: "Mon Miroir Intimate Collection",
          },
          {
            filename: "Fichier_009.jpeg",
            caption: "Mon Miroir Intimate Collection",
          },
          {
            filename: "Fichier_010.jpeg",
            caption: "Mon Miroir Intimate Collection",
          },
          {
            filename: "Fichier_011.jpeg",
            caption: "Mon Miroir Intimate Collection",
          },
        ],
      },
      {
        name: "Paradox",
        tagline: "Perfection opposition",
        description:
          "When the luster of gold meets the intensity of deep intense black. A collection sharply designed made with 24K gold leave and deep black and crystal high-quality acetate.",
        starting_price: 890,
        slideshow: [
          {
            caption: "Paradox Collection Frame 1",
            filename: "Fichier_000.jpeg",
          },
          {
            caption: "Paradox Collection Frame 2",
            filename: "Fichier_001.jpeg",
          },
          {
            caption: "Paradox Collection Frame 3",
            filename: "Fichier_002.jpeg",
          },
        ],
      },
      {
        name: "Intertwined",
        tagline: "Attractions between tradition and modernity",
        description:
          "Tactile deep dark buffalo horn glasses enhanced with a touch of metal modernity. A technical challenge associated with an uncompromising design.",
        starting_price: 1090,
        slideshow: [
          {
            filename: "Fichier_000.jpeg",
            caption: "Mon Miroir Intertwined Collection",
          },
          {
            filename: "Fichier_001.jpeg",
            caption: "Mon Miroir Intertwined Collection",
          },
          {
            filename: "Fichier_002.jpeg",
            caption: "Mon Miroir Intertwined Collection",
          },
          {
            filename: "Fichier_003.jpeg",
            caption: "Mon Miroir Intertwined Collection",
          },
          {
            filename: "Fichier_004.jpeg",
            caption: "Mon Miroir Intertwined Collection",
          },
          {
            filename: "Fichier_005.jpeg",
            caption: "Mon Miroir Intertwined Collection",
          },
          {
            filename: "Fichier_006.jpeg",
            caption: "Mon Miroir Intertwined Collection",
          },
          {
            filename: "Fichier_007.jpeg",
            caption: "Mon Miroir Intertwined Collection",
          },
          {
            filename: "Fichier_008.jpeg",
            caption: "Mon Miroir Intertwined Collection",
          },
        ],
      },
      {
        name: "YOU",
        tagline: "YOU are unique",
        description:
          "YOU: unique eyeglasses inspired by and dedicated to unique personalities. Mon Miroir of...",
        starting_price: 890,
        slideshow: [
          {
            filename: "Fichier_000.jpeg",
            caption: "Mon Miroir YOU Collection",
          },
          {
            filename: "Fichier_001.jpeg",
            caption: "Mon Miroir YOU Collection",
          },
          {
            filename: "Fichier_002.jpeg",
            caption: "Mon Miroir YOU Collection",
          },
          {
            filename: "Fichier_003.jpeg",
            caption: "Mon Miroir YOU Collection",
          },
          {
            filename: "Fichier_004.jpeg",
            caption: "Mon Miroir YOU Collection",
          },
        ],
      },
    ],
    branding: {
      MONMIROIR: "mon miroir",
      TAGLINE: "Handmade Luxury Eyewear",
    },
    preferences: {
      newsletter: {
        modalShown: false,
        signedUp: false,
      }
    }
  },
  mutations: {
    setModalSlideshowFromCollection(state, collection) {
      var newSlideShow = []
      collection.slideshow.forEach(slide => {
        newSlideShow.push({
          name: slide.filename,
          relURL: `/media/collections/${collection.name}/${slide.filename}`,
          caption: slide.caption,
          collection: collection.name
        });
      });
      state.modalSlideshow = newSlideShow;
    },
    setModalSlideshowFromInstafeed(state, feed) {
      var newSlideShow = [];
      feed.forEach((post) => {
        newSlideShow.push({
          name: post.id,
          relURL: post.media_url,
          caption: post.caption,
        });
      });
      state.modalSlideshow = newSlideShow;
    },
    setCarouselSlide(state, idx) {
      state.currentSlide = idx;
    },
    setNewsletterShown(state) {
      state.preferences.newsletter.modalShown = true;
    },
  },
  getters: {
    collectionsOptions(state) {
      return state.collections.map(c => { return { text: c.name, value: c.name } });
    },
  },
  actions: {
    triggerNewsletterModal({ commit, state }, { gaLabel, bvModal }) {
      if (!(state.preferences.newsletter.modalShown)) {
        bvModal.show('modal_nl');
        commit("setNewsletterShown");
        this._vm.$ga.query("send", "event", "popup", "newsletter", gaLabel, {});
      }  
    }
  },
  modules: {
  },
  plugins: [createPersistedState({
    paths: ['preferences']
  })],
})
