<template>
  <page isHome>
    <b-container fluid class="flex-grow-1 d-flex flex-column container-fluid">
      <b-jumbotron
        class="d-flex flex-column justify-content-between flex-grow-1"
      >
        <div class="jumbotext">
          <p>
            Mirror: “Which offers the image of something and somehow puts it in
            front of our eyes”
          </p>
        </div>

        <div class="jumbotext">
          <p><b-button variant="dark" :size="buttonSize" to="/recent"
            >Recent creations</b-button
          >&nbsp;
          <b-button variant="dark" :size="buttonSize" to="/collections"
            >Collections</b-button
          ></p>
        </div>

        <div class="jumbotext">
          <p>
            A Pair of glasses is the mirror of yourself, the mirror of what you
            feel, of who you are, want to be, want to show
          </p>
        </div>

        <!-- <b-row class="jumborow jumborow-1"></b-row>
        <b-row class="jumborow jumborow-1"></b-row>
        <b-row class="jumborow jumborow-1"><b-col cols="12"><p>TOTOTO</p></b-col></b-row>
        <b-row class="jumborow jumborow-1"></b-row>
        <b-row class="jumborow jumborow-1"><b-col cols="12"><b-button variant="dark" :size="buttonSize" to="/recent"
            >Recent creations</b-button
          >&nbsp;
          <b-button variant="dark" :size="buttonSize" to="/collections"
            >Collections</b-button
          ></b-col></b-row> -->


      </b-jumbotron>
    </b-container>
  </page>
</template>

<script>
// @ is an alias to /src
import Page from "@/views/Page.vue";
// import PHighlight from '../components/pHighlight.vue';

export default {
  name: "Home",
  data() {
    return {};
  },
  computed: {
    buttonSize() {
      return this.$mq === "md" || this.$mq === "lg" ? "lg" : "";
    },
  },
  components: {
    Page,
    // PHighlight,
  },
  methods: {},
};
</script>

<style scoped>
.jumbotron {
  background-image: url("../assets/media/backgroundtile.jpg");
  background-repeat: repeat-x repeat-y;
  font-size: 1.2rem;
  padding: 0;
}
@media (orientation: portrait) {
  .jumbotron {
    background-size: 20% auto;
  }
}
@media (orientation: landscape) {
  .jumbotron {
    background-size: 10%;
  }
}

html,
body,
#app {
  height: 100%;
}
#jumbo_bottom {
  text-align: center;
  padding: 1em;
}
div.jumbotext {
  text-align: center;
  background: #ffffffde;
}
div.jumbotext p {
  margin-top: 1em;
  font-size: 1.5rem;
}
@media (max-width: 500px) {
  div.jumbotext p {
    margin-top: 1em;
    font-size: 1.2rem;
  }
}
/* .jumborow {
  background-image: url("../assets/media/backgroundtile.jpg");
  background-repeat: repeat;
  margin-left: 0;
  margin-right: 0;
}
.jumborow-1 {
  flex: 0.2;
  background-size: auto 100%;
}
.jumborow-2 {
  flex: 0.4;
  background-size: auto 50%;
} */
</style>
