<template>
  <b-col sm="12" md="3" lg="2" class="mb-3">
    <b-card @click="cardClick" overlay :img-src="imgsrc" class="instacard" body-class="d-flex flex-column">
      <b-card-text class="mt-auto"
        >{{ caption
        }}<b-link target="_blank" :href="link" @click="trackOutbound(link)"
          ><b-icon icon="link45deg"></b-icon></b-link
      ></b-card-text>
    </b-card>
  </b-col>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["imgsrc", "caption", "link", "igid", "idx"],
  methods: {
    trackOutbound(link) {
      console.log("tracking outbound link: " + link);
      this.$ga.query("send", "event", "outbound", "click", link, {
        // transport: 'beacon',
        // hitCallback () {
        //   console.log("ga hitCallback");
        // }
      });
    },
    cardClick(event) {
      if (event.target.tagName === "svg") {
        return;
      } else {
        this.$emit("cardClick", this.idx)
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.card-body {
  padding: 0;
}
.card-body:hover > .card-text {
  display: block;
}

.card-text {
  display: none;
  padding: 1rem;
  background-color: #ffffffdd;
}
.instacard {
  height: 100%!important;
}
.instacard > div {
  height: 100%!important;
}
.instacard .card-img {
  object-fit: cover;
  height: 100%!important;
}
</style>
