<template>
  <b-modal
    id="modal_nl"
    v-model="modalShow"
    @shown="modalShown"
    hide-footer
    title="Let's stay in touch"
  >
    <template v-if="formSubmitted">
      <p>Thank you <b-icon-heart-fill variant="danger"></b-icon-heart-fill></p>
      <b-button block @click="modalShow = false;" type="button">Close</b-button>
    </template>
    <template v-else>
      <b-form @submit.prevent="onSubmit">
        <b-form-group
          label="Email address"
          label-for="form_email"
          label-sr-only
          description="We will never, share your email address with anyone your consent"
        >
          <b-form-input
            id="form_email"
            type="email"
            ref="email"
            placeholder="Your email address"
            required
            v-model="form.email"
          ></b-form-input>
        </b-form-group>
        <b-form-group description="By clicking the SUBSCRIBE button you agree to receive marketing and commercial information from MON MIROIR">
        <b-button block variant="dark" type="submit">Subscribe</b-button>
        </b-form-group>
      </b-form>
    </template>
  </b-modal>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      modalShow: false,
      form: {
        email: "",
      },
      formSubmitted: false,
      formError: false,
    };
  },
  methods: {
    modalShown() {
      this.$refs.email.$el.focus();
      this.$store.state.preferences.newsletter.modalShown = true;
    },
    onSubmit() {
      // Send event to GA
      this.$ga.query("send", "event", "form", "submit", "newsletter");

      const postData = {
        fields: [{ name: "email", value: this.form.email }, { name: "newsletter_opt_in", value: true } ],
        context: {
          hutk: this.$cookies.get("hubspotutk"),
          pageName: document.title,
          pageUri: window.location.href,
        },
      };
      axios
        .post(
          "https://api.hsforms.com/submissions/v3/integration/submit/9041682/2c064f76-382f-484c-b048-e56e94e0e738",
          postData
        )
        .then((response) => {
          if (response.status === 200) {
            this.formSubmitted = true;
          } else {
            this.formError = true;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>