<template>
  <b-navbar toggleable="lg">
    <b-navbar-brand to="/"><b-img src="@/assets/ui/logo-text-mm.svg"/> <!--strong>mon miroir</strong>&nbsp;<small>eyewear</small--></b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item to="/recent">Recent Creations</b-nav-item>
        <b-nav-item to="/collections">Collections</b-nav-item>
        <b-nav-item to="/about">About</b-nav-item>
        <b-nav-item to="/contact">Contact</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
    <!-- <nav
      class="navbar navbar-dark navbar-expand-lg fixed-top bg-white portfolio-navbar gradient"
    >
      <div class="container">
        <a class="navbar-brand logo" href="#">mon miroir</a
        ><button data-toggle="collapse" data-target="#navbarNav" class="navbar-toggler">
          <span class="sr-only">Toggle navigation</span
          ><span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="nav navbar-nav ml-auto">
            <li class="nav-item">
              <a class="nav-link" href="projects-grid-cards.html">Collections</a>
            </li>
            <li class="nav-item"><a class="nav-link" href="contact.html">About</a></li>
          </ul>
        </div>
      </div>
    </nav> -->
  </b-navbar>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.navbar {
  align-items: flex-start;
}
.navbar-brand img {
  max-height: 40px;
}
.navbar-toggler-icon { 
  width: 1.2em;
  height: 1.2em;
}
</style>