<template>
  <b-row>
    <slot name="intro" />
    <slot name="loading" :loading="loading" />
    <slot name="feeds" :items="feeds" />

    <b-col
      v-if="addlast && !loading"
      sm="12"
      md="3"
      lg="2"
      align-self="stretch"
      class="mb-3"
    >
      <b-card no-body bg-variant="dark" text-variant="light" class="h-100">
        <b-aspect aspect="1" class="flex">
          <b-card-body>
            <b-card-title title-tag="h3"
              ><b-link
                class="text-white"
                href="https://www.instagram.com/mon.miroir.samy/"
                >View More on Instagram</b-link
              ></b-card-title
            >
          </b-card-body>
        </b-aspect>
      </b-card>
    </b-col>

    <slot name="error" :error="error" />
  </b-row>
</template>

<script>
import axios from "axios";
export default {
  props: {
    /*
     * Instagram access token.
     */
    token: {
      type: String,
      required: true,
    },
    mock: {
      type: Boolean,
      required: false,
      default: false,
    },
    /*
     * Media Fields (see https://developers.facebook.com/docs/instagram-basic-display-api/reference/media#fields)
     */
    fields: {
      type: String,
      required: true,
    },
    /*
     * Number of posts rendered.
     */
    count: {
      type: Number,
      default: 11,
    },
    /*
     * Kinds of media to filter (Can be IMAGE, VIDEO, or CAROUSEL_ALBUM.).
     */
    mediatypes: {
      type: Array,
      required: true,
    },
    // Class for container div
    containerClass: {
      type: String,
      default: "",
      required: false,
    },
    addlast: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: () => ({
    error: null,
    loading: false,
    feeds: [],
    mockfeeds: [
      {
        media_url:
          "https://scontent-sjc3-1.cdninstagram.com/v/t51.2885-15/sh0.08/e35/c2.0.1436.1436a/s640x640/131344927_420646919137805_2474993357358010477_n.jpg?_nc_ht=scontent-sjc3-1.cdninstagram.com&_nc_cat=105&_nc_ohc=u__6xt4nBkoAX_w6ra4&tp=1&oh=a0f2128eed47d8394ed9e0edebdeaea4&oe=6005E103",
        media_type: "IMAGE",
        caption: "Caption2",
        permalink: "https://www.instagram.com/p/CIlu1b1g2kY/",
        id: "1",
      },
      {
        media_url:
          "https://scontent-sjc3-1.cdninstagram.com/v/t51.2885-15/sh0.08/e35/s640x640/130160172_891943128277995_2439550429799063210_n.jpg?_nc_ht=scontent-sjc3-1.cdninstagram.com&_nc_cat=103&_nc_ohc=qHaXuncMJisAX_G5Wju&tp=1&oh=1abea1a3028b1d36e80763cec8cee8b8&oe=6004B23F",
        media_type: "IMAGE",
        caption: "Caption1",
        permalink: "https://www.instagram.com/p/CIluymuA3kr/",
        id: "2",
      },
      {
        media_url:
          "https://scontent-sjc3-1.cdninstagram.com/v/t51.2885-15/sh0.08/e35/c343.0.753.753a/s640x640/129163893_396759101472396_4991288824505241809_n.jpg?_nc_ht=scontent-sjc3-1.cdninstagram.com&_nc_cat=100&_nc_ohc=bAGTyzYksHMAX-__8MX&tp=1&oh=b91f3df8234584e17e23d9e02d57a3a7&oe=600475D8",
        media_type: "IMAGE",
        caption: "Caption1",
        permalink: "https://www.instagram.com/p/CIluymuA3kr/",
        id: "3",
      },
      {
        media_url:
          "https://scontent-sjc3-1.cdninstagram.com/v/t51.2885-15/sh0.08/e35/s640x640/129725392_857982708309791_4877016144316518775_n.jpg?_nc_ht=scontent-sjc3-1.cdninstagram.com&_nc_cat=111&_nc_ohc=1J7Ab9mcfo4AX_jkz--&tp=1&oh=04a4bee02d6b936773d31e87e018e8ce&oe=60049E6E",
        media_type: "IMAGE",
        caption: "Caption1",
        permalink: "https://www.instagram.com/p/CIluymuA3kr/",
        id: "4",
      },
      {
        media_url:
          "https://scontent-sjc3-1.cdninstagram.com/v/t51.2885-15/sh0.08/e35/s640x640/129203384_839339943555460_4422033989481852589_n.jpg?_nc_ht=scontent-sjc3-1.cdninstagram.com&_nc_cat=104&_nc_ohc=ZnqRe27gsnoAX_7s5-C&tp=1&oh=8209c1484fcc5096550d8ce7bbfb61ff&oe=6004253B",
        media_type: "IMAGE",
        caption: "Caption1",
        permalink: "https://www.instagram.com/p/CIluymuA3kr/",
        id: "5",
      },
      {
        media_url:
          "https://scontent-sjc3-1.cdninstagram.com/v/t51.2885-15/e35/s320x320/131495094_247444850048721_1860772050863599003_n.jpg?_nc_ht=scontent-sjc3-1.cdninstagram.com&_nc_cat=109&_nc_ohc=jhsqXjZcIIsAX_FxJCc&tp=1&oh=50f2a8ebb131cf0dbf8a58242837e1ce&oe=60054639",
        media_type: "IMAGE",
        caption: "Caption1",
        permalink: "https://www.instagram.com/p/CIluymuA3kr/",
        id: "6",
      },
      {
        media_url:
          "https://scontent-sjc3-1.cdninstagram.com/v/t51.2885-15/e35/c51.0.1337.1337a/s320x320/125334674_1465932893610981_3291152329175421049_n.jpg?_nc_ht=scontent-sjc3-1.cdninstagram.com&_nc_cat=109&_nc_ohc=nrDH_eIF6JsAX-sdt8v&tp=1&oh=f773320f9faaa19e6feffbca58dee257&oe=6004BEBF",
        media_type: "IMAGE",
        caption: "Caption1",
        permalink: "https://www.instagram.com/p/CIluymuA3kr/",
        id: "7",
      },
      {
        media_url:
          "https://scontent-sjc3-1.cdninstagram.com/v/t51.2885-15/e35/s320x320/125219856_2794643537476885_4874751538026766273_n.jpg?_nc_ht=scontent-sjc3-1.cdninstagram.com&_nc_cat=101&_nc_ohc=lL9gMs_AEBMAX-xTtKW&tp=1&oh=b72810c7ad203a78011cc3b991ee07b6&oe=6007B3A4",
        media_type: "IMAGE",
        caption: "Caption1",
        permalink: "https://www.instagram.com/p/CIluymuA3kr/",
        id: "8",
      },
      {
        media_url:
          "https://scontent-sjc3-1.cdninstagram.com/v/t51.2885-15/e35/s320x320/125055322_1317368201949306_1608373277079670963_n.jpg?_nc_ht=scontent-sjc3-1.cdninstagram.com&_nc_cat=106&_nc_ohc=ZZ6-CMOffQsAX9EMtNU&tp=1&oh=71d7bfb0e8cbc8270d57a914b11f0469&oe=60058C5D",
        media_type: "IMAGE",
        caption: "Caption1",
        permalink: "https://www.instagram.com/p/CIluymuA3kr/",
        id: "9",
      },
      {
        media_url:
          "https://scontent-sjc3-1.cdninstagram.com/v/t51.2885-15/e35/c2.0.1435.1435a/s320x320/123214186_2561739110783295_413311559334089386_n.jpg?_nc_ht=scontent-sjc3-1.cdninstagram.com&_nc_cat=103&_nc_ohc=-SRuL0tXQWkAX_70XgZ&tp=1&oh=1271d71db5df064eba1959007f1c8865&oe=6007363C",
        media_type: "IMAGE",
        caption: "Caption1",
        permalink: "https://www.instagram.com/p/CIluymuA3kr/",
        id: "10",
      },
      {
        media_url:
          "https://scontent-sjc3-1.cdninstagram.com/v/t51.2885-15/e35/c2.0.1436.1436a/s320x320/123017632_114893320403267_6477610852233680806_n.jpg?_nc_ht=scontent-sjc3-1.cdninstagram.com&_nc_cat=111&_nc_ohc=64b_QtLAKEkAX9jY7Jq&tp=1&oh=cb305ea3818df84e7037d4261f71c1cc&oe=6006C978",
        media_type: "IMAGE",
        caption: "Caption1",
        permalink: "https://www.instagram.com/p/CIluymuA3kr/",
        id: "11",
      },
      // {
      //   media_url:
      //     "https://scontent-sjc3-1.cdninstagram.com/v/t51.2885-15/e35/s320x320/129275214_2762750570639854_1755944274711939751_n.jpg?_nc_ht=scontent-sjc3-1.cdninstagram.com&_nc_cat=110&_nc_ohc=3k7Ql3eFPgIAX8in-Q2&tp=1&oh=5c4df29c7a5224b0c80d4bf9ae5055d9&oe=600733CB",
      //   media_type: "IMAGE",
      //   caption: "Caption1",
      //   permalink: "https://www.instagram.com/p/CIluymuA3kr/",
      //   id: "12",
      // },
    ],
  }),
  mounted() {
    this.getUserFeed();
  },
  methods: {
    getUserFeed() {
      this.loading = true;
      // console.log("addlast = " + this.addlast);
      if (this.mock) {
        this.feeds = this.mockfeeds;
        this.loading = false;
      } else {
        axios.get("https://graph.instagram.com/refresh_access_token", {
          params: {
            grant_type: "ig_refresh_token",
            access_token: this.token,
          }
        });
        axios
          .get("https://graph.instagram.com/me/media", {
            params: { access_token: this.token, fields: this.fields },
          })
          .then((response) => {
            this.loading = false;
            if (response.status === 400) {
              this.error = response.error.message;
            }
            if (response.status === 200) {
              var idx = 0;
              for (const n in response.data.data) {
                if (
                  this.mediatypes.includes(response.data.data[n].media_type)
                ) {
                  const el = response.data.data[n];
                  if (el.caption) {
                    el.caption = this.formatInstaCaption(el.caption);
                  }
                  el["idx"] = idx++;
                  this.feeds.push(el);
                  if (this.feeds.length >= this.count) {
                    this.$emit("feedloaded", this.feeds);
                    return;
                  }
                }
              }

              // this.feeds.push({id: "last"});
            }
          })
          .catch((error) => {
            throw error;
          });
      }
    },
    formatInstaCaption(caption) {
      var maxLength = 100;
      var ret = caption.replace(/#\w\w+\s?/g, '');
      if (ret.length > maxLength) {
        ret =  ret.substr(0, ret.lastIndexOf(" ", maxLength)) + "...";
      }
      return ret;
    }
  },
};
</script>

<style scoped>
.card-body {
  height: 100%;
  display: flex;
  align-items: center;
}
</style>