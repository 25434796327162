<template>
  <b-modal
    id="bvmodal"
    v-model="modalShow"
    @shown="modalShown"
    @hidden="modalHidden"
    hide-header
    hide-footer
  >
    <b-carousel
      ref="modalCarousel"
      v-model="currentSlide"
      no-touch
      indicators
      controls
      :interval="0"
      @sliding-start="gaTrackSliding"
    >
      <b-carousel-slide
        v-for="slide in modalSlideshow"
        :key="slide.name"
        :img-src="slide.relURL"
        :img-alt="slide.caption"
      ></b-carousel-slide>
    </b-carousel>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
// import fullscreen from 'vue-fullscreen'
// import Vue from 'vue'
// Vue.use(fullscreen)

export default {
  data() {
    return {
      modalShow: false,
    };
  },
  computed: {
    ...mapState(["modalSlideshow"]),
    currentSlide: {
      get() {
        return this.$store.state.currentSlide;
      },
      set(val) {
        this.$store.commit("setCarouselSlide", val);
      },
    },
  },
  methods: {
    modalShown() {
      // this.$fullscreen.toggle(document.getElementById("bvmodal___BV_modal_outer_"), {
      //   wrap: false,
      // })
      this.$refs.modalCarousel.$el
        .querySelector(".carousel-control-prev")
        .focus();
    },
    gaTrackSliding(slide) {
      this.$ga.query(
        "send",
        "event",
        "carousel",
        "modalSlide",
        this.modalSlideshow[0].collection + ": " + slide,
        {}
      );
    },
    modalHidden() {
        this.$store.dispatch("triggerNewsletterModal", { bvModal: this.$bvModal, gaLabel: "modalSlideshowClose"});
    },
  },
  watch: {},
};
</script>

<style >
.modal {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

#bvmodal___BV_modal_outer_ .modal-dialog {
  max-width: 100vh;
  margin: 0 auto;
  max-height: 100vw;
}

#bvmodal___BV_modal_outer_ .modal-backdrop {
  opacity: 1;
}

.modal .carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #666666;
  outline: none;
}

/* .carousel-control-next-icon,
.carousel-control-prev-icon {
  filter: invert(1);
} */
</style>