import { render, staticRenderFns } from "./InstaCard.vue?vue&type=template&id=06f64735&scoped=true&"
import script from "./InstaCard.vue?vue&type=script&lang=js&"
export * from "./InstaCard.vue?vue&type=script&lang=js&"
import style0 from "./InstaCard.vue?vue&type=style&index=0&id=06f64735&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06f64735",
  null
  
)

export default component.exports